<template>
  <div>
    <div class="ml-2">
      <!-- <a :href="exportData()"
                >-->
      <primaryButton
        type="button"
        label="Export As CSV"
        icon
        color="primary"
        @click="csvExport(currentTable.currentItems)"
        id="exportExplorer"
      ></primaryButton>
      <!--  </a>-->
    </div>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: { primaryButton },
  props: ["currentTable"],
  data() {
    return { openDialogFlag: false };
  },
  methods: {
    csvExport(arrData) {
      console.log(arrData.length);
      if (arrData.length > 0) {
        let newList = [];
        arrData.forEach((info) => {
          let cStatus = "";
          let lastUpdate = "";
          let eventDate = "";
          let tags = "";
          if (
            typeof info.explorerStatusInfo != "undefined" &&
            info.explorerStatusInfo != null
          ) {
            cStatus = info.explorerStatusInfo.n_currentStatus;
            lastUpdate = info.explorerStatusInfo.lastUpdate_formatted;
            eventDate = info.explorerStatusInfo.eventDate_formatted;
          }
          if (typeof info.c_tags != "undefined" && info.c_tags != null) {
            let source = info.c_tags;
            tags = source.join(" ");
          }
          let onlineMapping = { 0: "Offline", 1: "Online", 2: "Warning" };

          let description = info.c_description;
          //console.log(info);

          if (description == null) {
            description='';
            }
            description = '"' + description + '"';
          //  if (typeof info!='undefined'  && typeof info.c_description!='undefined' && info.c_description!= null && (info.c_description.includes(";") || info.c_description.includes("\n") ||info.c_description.includes(","))) {
          //   description =  "\"" + info.c_description+ "\"";
          //   // description = info.c_description.replace(/[^a-zA-Z0-9 ]/g, '');
          //  }

          newList.push({
            Name: info.c_name,
            Status: cStatus,
            "Update Time": lastUpdate,
            "Event Time": eventDate,
            Description: description,
            Tags: tags,
            "Operational State": onlineMapping[info.online],
            "Operational State Since": info.onlineSince,
          });
          // console.log(description);
        });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + "-" + mm + "-" + dd;
        var MagicWord = "\uFEFF";
        let csvContent = "data:text/csv;charset=utf-8," + MagicWord;
        let columnDelimiter = ";";
        csvContent += [
          Object.keys(newList[0]).join(columnDelimiter),
          ...newList.map((item) => Object.values(item).join(columnDelimiter)),
        ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");
        //csvContent= csvContent.replaceAll(";",",");
        const data = encodeURI(csvContent);
        // console.log("data--->", data);
        // console.log("b4--->", csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "export-" + today + ".csv");
        link.click();
      } else {
        this.$notify({
          group: "foo",
          text: "No Records Found!",
          duration: 2000,
          type: "warning",
          speed: 600,
          ignoreDuplicates: true,
        });
      }
    },
    /*exportData() {

        let baseURL = "serve.php?f=administration&f2=explorers";
        let statusType = typeof this.currentTable.selectedStatus != 'undefined' &&
        this.currentTable.selectedStatus != '' ? this.currentTable.selectedStatus : 'all';
        let testGroupValue = typeof this.currentTable.groupFilterExplorer != 'undefined' &&
        this.currentTable.groupFilterExplorer != '' ? this.currentTable.groupFilterExplorer : '';

        let functionName = "exportExplorer";
        let searchInput = typeof this.currentTable.searchValue != 'undefined' &&
        this.currentTable.searchValue != '' ? this.currentTable.searchValue : '';
        return baseURL + "&for=csv&searchInput="+searchInput+"&statusType=" + statusType + "&testGroupValue=" + JSON.stringify(testGroupValue) + "&function=" + functionName + "&requestType=ajax";
      }*/
  },
};
</script>